.cover-box {
  background-color: #f7f7f7;
  padding: 20px 5vw;
}
.inner-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.search-wrapper {
  background-color: white;
  padding: 2px 5px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 40vw;
}
.inputbase:focus {
  border: none;
  outline: none;
}
.area:focus {
  border: none;
  outline: none;
}
.area {
  outline-width: 0;
  width: 40%;
  height: 100px;
  border-radius: 4px;
  border: 2px solid #EEEEEE;
  padding: 0px 4px;
  resize: none;
}
.img {
  display: block;
}
@media only screen and (max-width: 900px) {
  .over-box {
    padding: 10px 2vw;
  }
  .inner-box {
    flex-direction: column;
  }
  .search-wrapper {
    width: 90vw;
  }
  .img {
    display: none;
  }
  .area {
    width: 95%;
  }
}
